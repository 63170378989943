<template>
    <v-dialog v-model="verifModal.value" width="auto">
        <v-card>
            <v-col align=center>
                <div class="my-2" style="font-weight: bold;">
                    <!-- Valider la suppression -->
                    {{ verifModal.text }}
                </div>
                <v-row>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: red; color: white;" @click="verifModal = {}">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn class="button_armo" style="background-color: green; color: white;" @click="verifModal.callback">
                            OUi
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isClickedImgOpen" width="99%" max-width="800">
        <v-card>
            <v-col class="pa-1">
                <img style="width: 100%;" :src="clickedImgUrl" alt="imgtoshow">
            </v-col>
        </v-card>
    </v-dialog>
    <v-card :color="isMobile ? 'white' : 'transparent'" style="border-radius: 0px;">
        <div style="height:100vh">
            <v-col class="pa-1" cols="12">
                <v-card class="pa-3 bg" variant="flat" style="border: solid 1px var(--C1);" align="center">
                    <v-row justify="center" align="center">
                        <v-col>
                            <v-card class="pa-2">
                                <v-file-input chips closable v-model="addedPhoto" label="Ajouter une image"
                                    variant="filled" prepend-icon="mdi-camera" hide-details clearable multiple
                                    density="compact"></v-file-input>
                            </v-card>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn class="buttonReverse button_armo" :disabled="disabledValidation" height="56px"
                                style="width: 100%;" :variant="disabledValidation ? 'text' : 'flat'"
                                @click="addPhotoToServer" :loading="disabledValidation">Valider</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col class="pa-1 pt-0" cols="12">
                <v-card v-if="photoListe" class="bg">
                    <v-col class="pa-4">
                        <v-row>
                            <v-col cols="12" md="6" class="pa-1">
                                <v-select :items="tmpGroupInformation.album_data.tagsListe" v-model="filteredTags"
                                    bg-color="white" label="Filtre" multiple hide-details>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index < 2">
                                            <span>{{ item.title }}</span>
                                        </v-chip>
                                        <span v-if="index === 2" class="text-grey text-caption align-self-center">
                                            (+{{ filteredTags.length - 2 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6" class="pa-1">
                                <v-row justify="center" align=center>
                                    <v-col cols="">
                                        <v-text-field class="" label="Ajouter un mot-clef de correspondance d’image"
                                            v-model="tmpTag" hide-details bg-color="white"></v-text-field>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn icon="mdi-plus"
                                            :disabled="tmpTag == undefined ? true : !tmpTag.replaceAll(' ', '').length > 0"
                                            @click="addTag"></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="pt-0 px-2">
                        <div class="view">
                            <div class="wrapper">
                                <v-table
                                    :height="isMobileXs ? getOperatingSystem == 'IOS' ? '39vh' : '45vh' : isMobile ? '58vh' : '66vh'"
                                    fixed-header class="table">
                                    <thead>
                                        <tr v-if="!isMobileXs">
                                            <th class="sticky-col" style="z-index: 3;">
                                                Catégories
                                            </th>
                                            <th v-for="tag in tmpGroupInformation.album_data.tagsListe" :key="tag"
                                                style="z-index: 1" align=center>
                                                <v-chip v-if="isMobile"
                                                    style="background-color: transparent; width: 100%;" align=center
                                                    class="pb-5 pt-6 px-5">
                                                    <v-span>
                                                        <input type="checkbox" v-model="tagsAll[tag]"
                                                            style="cursor: pointer;transform: scale(1.5);"
                                                            @change="setTagsAll(tag, tagsAll[tag])">
                                                        <label class="mx-4"
                                                            style="font-weight: bold; color: black; text-transform: capitalize;">
                                                            {{ tag }}
                                                        </label>
                                                        <v-btn icon="mdi-minus" variant="text" density="compact"
                                                            class="ml-0" color="red"
                                                            @click="removeTagFromTagListe(tag)"></v-btn>
                                                    </v-span>
                                                </v-chip>
                                                <v-col v-else style="background-color: transparent; width: 100%;"
                                                    align=center>
                                                    <v-span>
                                                        <input type="checkbox" v-model="tagsAll[tag]"
                                                            style="cursor: pointer;transform: scale(1.5);"
                                                            @change="setTagsAll(tag, tagsAll[tag])">
                                                        <label class="mx-4"
                                                            style="font-weight: bold; color: black; text-transform: capitalize;">
                                                            {{ tag }}
                                                        </label>
                                                        <v-btn icon="mdi-minus" variant="text" density="compact"
                                                            class="ml-0" color="red"
                                                            @click="removeTagFromTagListe(tag)"></v-btn>
                                                    </v-span>
                                                </v-col>
                                                <!-- <v-row align=center justify="center">
                                                    <v-col align=center cols="auto" class="px-1">
                                                        <input type="checkbox" v-model="tagsAll[tag]"
                                                            style="cursor: pointer;transform: scale(1.5);"
                                                            @change="setTagsAll(tag, tagsAll[tag])">
                                                    </v-col>
                                                    <v-col align=center cols="auto" class="pl-1 pr-0">
                                                        <label
                                                            style="font-weight: bold; color: black; text-transform: capitalize;">
                                                            {{ tag }}</label>
                                                        <v-btn icon="mdi-minus" variant="text" density="compact"
                                                            class="ml-1" color="red"
                                                            @click="removeTagFromTagListe(tag)"></v-btn>
                                                    </v-col>
                                                </v-row> -->
                                            </th>
                                            <th class="text-left">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="!isMobileXs">
                                        <tr v-for="(value, ind) in getPhotoListeFilterer" :key="ind">
                                            <td class="sticky-col" :style="{
                                                'width': isMobile ? '130px' : '200px',
                                                'min-width': isMobile ? '130px' : '200px',
                                                'max-width': isMobile ? '130px' : '200px'
                                            }">
                                                <img class="bg pa-2 mt-2" loading="lazy"
                                                    :src="'https://' + VUE_APP_USE_URL + '/medias/album/' + value"
                                                    alt="Image" style="height: 70px;"
                                                    :style="{ 'width': isMobile ? '100px' : '200px' }"
                                                    @click="showImgFromUrl('https://' + VUE_APP_USE_URL + '/medias/album/' + value)">
                                            </td>
                                            <td v-for="(tag, idx) in tmpGroupInformation.album_data.tagsListe"
                                                :key="idx" class="text-center" style="z-index: 0;" align="center">
                                                <v-chip class="buttonReverse" @click="setTagToItem(value, tag)"
                                                    style="cursor: pointer;border-radius: 0px;" :style="(tmpGroupInformation.album_data['album'][value] &&
                                                        tmpGroupInformation.album_data['album'][value].includes(tag)) ? 'background-color:var(--C1) !important;color:white !important' : ''
                                                        ">
                                                    {{ tag }}
                                                </v-chip>
                                            </td>
                                            <td align="right">
                                                <v-btn @click="deleteImg(value)" color="white" variant="flat"
                                                    class="buttonReverse">
                                                    <v-icon color=red size="20">
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-for="(value, ind) in getPhotoListeFilterer" :key="ind">
                                            <td class="sticky-col" :style="{
                                                'width': isMobile ? '130px' : '200px',
                                                'min-width': isMobile ? '130px' : '200px',
                                                'max-width': isMobile ? '130px' : '200px'
                                            }">
                                                <img class="bg pa-2 mt-2" loading="lazy"
                                                    :src="'https://' + VUE_APP_USE_URL + '/medias/album/' + value"
                                                    alt="Image" style="height: 70px;"
                                                    :style="{ 'width': isMobile ? '100px' : '200px' }"
                                                    @click="showImgFromUrl('https://' + VUE_APP_USE_URL + '/medias/album/' + value)">
                                            </td>
                                            <td style="width: 100%;">
                                                <v-row justify="center" align=center>
                                                    <v-col cols="10" class="pa-0">
                                                        <v-select style="border: solid 1px grey; border-radius: 5px;"
                                                            label="catégorie" class="mt-2"
                                                            v-model="tmpGroupInformation.album_data['album'][value]"
                                                            :items="tmpGroupInformation.album_data.tagsListe" multiple
                                                            hide-details>
                                                            <template v-slot:selection="{ item, index }">
                                                                <v-chip v-if="index < 1">
                                                                    <span>{{ item.title }}</span>
                                                                </v-chip>
                                                                <span v-if="index === 1"
                                                                    class="text-grey text-caption align-self-center">
                                                                    (+{{
                                                                        tmpGroupInformation.album_data['album'][value].length
                                                                        - 1 }} others)
                                                                </span>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="2" align="center" class="pa-0">
                                                        <v-btn @click="deleteImg(value)" color="white" icon="mdi-delete"
                                                            variant="flat" class="buttonReverse" density="compact">
                                                            <v-icon color=red size="20">
                                                                mdi-delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </div>
                        </div>
                    </v-col>
                    <v-col align=center class="pt-0">
                        <v-btn class="buttonReverse button_armo" @click="updatePhotoName">
                            Mettre à jour les catégories
                        </v-btn>
                    </v-col>
                </v-card>
                <div v-else align="center"
                    style="color: white;padding-top: 20vh; font-weight: bold; font-size: 25px; color: var(--C1);">
                    La galerie d'images est actuellement vide.
                </div>
            </v-col>
        </div>
    </v-card>
</template>

<script>

import engine from '@/core/index'

export default {
    data() {
        return {
            VUE_APP_USE_URL: process.env.VUE_APP_USE_URL,  
            verifModal: {},
            filteredTags: [],
            tagsAll: {},
            tmpTag: undefined,
            newTagsListe: [],
            photoListe: undefined,
            disabledValidation: false,
            showNoTags: false,
            addedPhoto: undefined,
            isAlbumModalOpen: false,
            selectedImg: undefined,
            filesListe: [],
            tmpNewTag: undefined,
            tmpGroupInformation: undefined,
            filterListe: [],
            selectedFilter: [],
            isClickedImgOpen: false,
            clickedImgUrl: undefined
        }
    },
    props: {
        getOperatingSystem: {},
        groupInformation: {},
        getUserData: { type: Function }
    },
    mounted() {
        this.tmpGroupInformation = JSON.parse(JSON.stringify(this.groupInformation))
        this.getAllFilesInAlbum()
    },
    methods: {
        removeTagFromTagListe(tag) {
            let that = this
            this.verifModal = {
                text: "Supprimer la catégorie : " + tag,
                value: true,
                callback: function () {
                    for (let i = 0; i < that.photoListe.length; i++) {
                        let key = that.photoListe[i]

                        if (that.tmpGroupInformation.album_data['album'][key] != undefined && that.tmpGroupInformation.album_data['album'][key].includes(tag)) {
                            let index = that.tmpGroupInformation.album_data['album'][key].findIndex((val) => val == tag)
                            that.tmpGroupInformation.album_data['album'][key].splice(index, 1)
                        }
                    }

                    let index = that.tmpGroupInformation.album_data.tagsListe.findIndex((val) => val == tag)
                    that.tmpGroupInformation.album_data.tagsListe.splice(index, 1)
                    that.verifModal = {}
                }
            }

        },
        setTagToItem(url, tag) {
            if (this.tmpGroupInformation.album_data['album'] == undefined)
                this.tmpGroupInformation.album_data['album'] = {}

            if (this.tmpGroupInformation.album_data['album'][url] == undefined)
                this.tmpGroupInformation.album_data['album'][url] = []

            if (this.tmpGroupInformation.album_data['album'][url].includes(tag)) {
                let index = this.tmpGroupInformation.album_data['album'][url].findIndex((val) => val == tag)
                this.tmpGroupInformation.album_data['album'][url].splice(index, 1)
            } else {
                this.tmpGroupInformation.album_data['album'][url].push(tag)
            }
        },
        showImgFromUrl(url) {
            this.clickedImgUrl = url
            this.isClickedImgOpen = true
        },
        setTagsAll(tag, select) {
            if (select) {
                for (let i = 0; i < this.getPhotoListeFilterer.length; i++) {
                    let key = this.getPhotoListeFilterer[i]

                    if (this.tmpGroupInformation.album_data['album'][key] == undefined || !this.tmpGroupInformation.album_data['album'][key].includes(tag)) {
                        if (this.tmpGroupInformation.album_data['album'][key] == undefined)
                            this.tmpGroupInformation.album_data['album'][key] = []
                        this.tmpGroupInformation.album_data['album'][key].push(tag)
                    }
                }
            } else {
                for (let i = 0; i < this.getPhotoListeFilterer.length; i++) {
                    let key = this.getPhotoListeFilterer[i]

                    if (this.tmpGroupInformation.album_data['album'][key] != undefined && this.tmpGroupInformation.album_data['album'][key].includes(tag)) {
                        let index = this.tmpGroupInformation.album_data['album'][key].findIndex((val) => val == tag)
                        this.tmpGroupInformation.album_data['album'][key].splice(index, 1)
                    }
                }
            }
        },
        updatePhotoName() {
            let that = this
            this.verifModal = {
                text: "Enregistrer mes modifications ?",
                value: true,
                callback: function () {
                    for (const [key, value] of Object.entries(that.tmpGroupInformation.album_data.album)) {
                        if (value.length == 0)
                            delete that.tmpGroupInformation.album_data.album[key]
                    }
                    engine.update_album_data(JSON.stringify(that.tmpGroupInformation.album_data), that.tmpGroupInformation.id, function () {
                        that.getUserData()
                    })
                    that.verifModal = {}
                }
            }
        },
        addTag() {
            if (this.tmpGroupInformation.album_data['tagsListe'] == undefined)
                this.tmpGroupInformation.album_data['tagsListe'] = []
            if (this.tmpGroupInformation.album_data['album'] == undefined)
                this.tmpGroupInformation.album_data['album'] = {}
            this.tmpGroupInformation.album_data['tagsListe'].push(this.tmpTag)
            this.tmpTag = undefined
        },
        getColorFromWord(word) {
            // Calculer une valeur de hachage basée sur les caractères ASCII du mot
            var hash = 0;
            for (var i = 0; i < word.length; i++) {
                hash = word.charCodeAt(i) + ((hash << 5) - hash);
            }

            // Formater le code hexadécimal
            var color = '#';
            for (var j = 0; j < 3; j++) {
                var value = (hash >> (j * 8)) & 0xFF;
                color += ('00' + value.toString(16)).substr(-2);
            }

            return color;
        },
        elementEnCommun(tableau, autreTableau) {
            return autreTableau.every(element => tableau.includes(element));
        },
        deleteImg(fileName) {
            let that = this
            this.verifModal = {
                text: "Valider la suppression",
                value: true,
                callback: function () {
                    engine.file_delete(fileName, function () {
                        if (that.tmpGroupInformation.album_data['album'] && that.tmpGroupInformation.album_data['album'][fileName])
                            delete that.tmpGroupInformation.album_data['album'][fileName]
                        that.getAllFilesInAlbum()
                        that.verifModal = {}
                    })
                }
            }
        },
        reload() {
            this.getAllFilesInAlbum()
        },
        async addPhotoToServer() {
            let that = this

            if (this.addedPhoto && this.addedPhoto.length > 0)
                for (let i = 0; i < this.addedPhoto.length; i++) {
                    this.disabledValidation = true
                    this.getBase64Image(this.addedPhoto[i], function (dateUrl) {
                        engine.save_file_in_group_album(that.tmpGroupInformation.id, [dateUrl], function () {

                            if (i + 1 == that.addedPhoto.length) {
                                that.addedPhoto = []
                                that.getAllFilesInAlbum()
                                // that.formatDataBeforeUpload()
                                that.disabledValidation = false
                            }
                        })
                    })
                }
        },
        async getBase64Image(file, callback) {
            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = function () {
                let img = new Image();
                img.src = reader.result;

                img.onload = function () {
                    let canvas = document.createElement('canvas');
                    let ctx = canvas.getContext('2d');

                    canvas.width = img.width;
                    canvas.height = img.height;

                    ctx.drawImage(img, 0, 0);

                    let webpBase64 = canvas.toDataURL('image/webp');
                    callback(webpBase64);
                };
            };
        },
        getAllFilesInAlbum() {
            let that = this
            this.filesListe = []
            engine.get_all_files_in_group_album(this.tmpGroupInformation.id, function (data) {
                if (data.state == 200) {
                    that.photoListe = data.data
                }
            })

        },

    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        },
        isMobileXs() {
            return this.$vuetify.display.xs
        },
        getPhotoListeFilterer() {
            let filteredPhotoListe = []

            if (this.filteredTags == undefined || this.filteredTags.length == 0)
                return this.photoListe
            for (let i = 0; i < this.photoListe.length; i++) {
                let photo = this.photoListe[i]
                let tags = this.tmpGroupInformation.album_data.album[photo]
                if (tags && tags.some(item => this.filteredTags.includes(item)))
                    filteredPhotoListe.push(photo)
            }
            return filteredPhotoListe
        },
    },
    watch: {
    }
}

</script>

<style>
.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    left: 0px;
    z-index: 10;
}
</style>