<template>
    <v-dialog v-model="modalIsOpen['editShopifyArticle']" persistent fullscreen>
        <div align="center">
            <editShopifyArticle :getOperatingSystem="getOperatingSystem"
                :closeModal="closeModal" :userInformation="userInformation" :groupInformation="groupInformation"
                :closeFunction="updateTableAndCloseEditionModalLocal" :dataForm="modalData"
                :windowHeight="windowHeight" :siteListe="blogListe">
            </editShopifyArticle>
        </div>
    </v-dialog>
    <div v-if="user_squeduel_liste && getUser_squeduel_liste && getUser_squeduel_liste.length == 0"
        style="position: absolute;left:0px;z-index: 2;color: black;right:0px;top:35vh" align="center">
        <h3>
            Aucune planification disponible
        </h3>
    </div>
    <!-- <v-icon v-if="!isMobileXs" @click="updateData"
        style="border-radius: 100%; font-size: 30px;position: absolute;z-index: 2;right:19px;top:15px;color:white;cursor: pointer;">
        mdi-refresh
    </v-icon> -->
    <v-col class="">
        <v-row align="center">
            <v-col md="12" cols="12" class="px-1 pt-1 pb-0">
                <v-card style="background-color: #E7E7E7;border-radius: 3px;">
                    <v-text-field class="px-2" v-model="tmp_searh_value" @keydown.enter="setSearch()" clearable
                        bg-color="#E7E7E7" variant="flat" placeholder="Rechercher..." prepend-icon="mdi-magnify"
                        hide-details></v-text-field>
                </v-card>
            </v-col>
            <v-col class="" cols="12" align=left>
                <v-row align="center">
                    <v-col cols="auto" class="pl-1" :class="isMobileXs ? 'pt-3' : 'pt-7'">
                        <div style="color: white;">
                            Nombre d'article{{ selected_articles.length > 1 ? 's' : '' }} sélectionné{{
                                selected_articles.length >
                                    1
                                    ? 's' : '' }} : {{ selected_articles.length }}
                        </div>
                    </v-col>
                    <v-col cols="auto" :class="isMobileXs ? 'pa-0' : 'pt-5 pb-0'" style="">
                        <!-- <div v-if="selectedView != 1"> -->
                        <div>
                            <v-btn class="mr-2 mb-2 btn-hover" @click="deleteSelectedArticle" variant="tonal"
                                :disabled="!selected_articles.length > 0"
                                style="color:white;border: solid 1px white;"><v-icon
                                    size="25">mdi-delete-outline</v-icon>
                                <div v-if="!isMobile" class="pl-2">Supprimer</div>
                            </v-btn>
                            <v-btn class="mr-2 mb-2 btn-hover" @click="deprogrameSelectedArticle" variant="tonal"
                                :disabled="!selected_articles.length > 0 || selectedView == 1"
                                style="color:white;border: solid 1px white;"><v-icon
                                    size="25">mdi-file-clock-outline</v-icon>
                                <div v-if="!isMobile" class="pl-2">
                                    {{ showPostInfo ? 'Déprogrammer' : 'Archiver' }}
                                </div>
                            </v-btn>
                            <v-btn class="mr-2 mb-2 btn-hover" @click="reprogrameSelectedArticle" variant="tonal"
                                :disabled="!selected_articles.length > 0 || selectedView == 1"
                                style="color:white;border: solid 1px white;"><v-icon
                                    size="25">mdi-file-check-outline</v-icon>
                                <div v-if="!isMobile" class="pl-2">
                                    {{ showPostInfo ? 'Re-programmer' : 'Désarchiver' }}
                                </div>
                            </v-btn>
                            <v-btn v-if="showPostInfo" class="mr-2 mb-2 btn-hover" @click="publishNowSelectedArticle"
                                :disabled="!selected_articles.length > 0 || selectedView == 1" readonly variant="tonal"
                                style="color:white;border: solid 1px white;"><v-icon size="40"
                                    class="pr-2">mdi-language-go</v-icon>
                                <div v-if="!isMobile" class="pl-2">Publier maintenant</div>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" class="pl-5 pb-0">
                <v-row>
                    <v-col v-for="(value, key) in tab_filter" :key="key" cols="auto" class="pa-0">
                        <div v-if="value.value && value.value != undefined && value.show" class="pa-1">
                            <v-chip @click="tab_filter[key].value = undefined"
                                style="cursor: pointer;border-radius: 10px;">
                                <div v-if="key == 'date'">
                                    {{ value.label }} : {{ formatDate(value.value, 'LL') }}
                                </div>
                                <div v-else>
                                    {{ value.label }} : {{ value.value }}
                                </div>
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-col>
    <v-card>
        <!-- :style="{ height: isMobileXs ? 'calc(100vh - 300px)' : 'calc(100vh - 280px)', }" -->
        <Vue3EasyDataTable class="local-scrollbar" :headers="headers" theme-color="var(--BG)"
            v-model:items-selected="selected_articles" :items="getCurrentSelectedDataView"
            :table-height="isMobileXs ? getOperatingSystem == 'IOS' ? windowHeight * 0.47 : windowHeight * 0.515 : windowHeight * 0.655"
            :table-min-height="isMobileXs ? getOperatingSystem == 'IOS' ? windowHeight * 0.47 : windowHeight * 0.515 : windowHeight * 0.655"
            table-class-name="customize-table" @click-row="openProgrammation" rows-per-page-message="Articles par page"
            empty-message="" header-text-direction="center" body-text-direction="center">
            <template #header-id="header">
                <div align="left" style="width: 100%; padding-left: 35px;">
                    {{ header.text }}
                </div>
            </template>
            <template #item-id="{ image_path, title, id, description, creator, target_query }">
                <v-col v-if="description && description.length > 0">
                    <v-row v-if="!isMobileXs" style="padding:10px 10px;" justify="left" align="center">
                        <v-col cols="auto" align=center>
                            <img :src="image_path" height="70" cover
                                style="border-radius: 5px;border: solid lightgray 1px;" />
                        </v-col>
                        <v-col cols="auto" style="padding-left: 3vw;" class="pa-1" align="left">
                            <div class="pb-2" style="font-weight: 1000;">
                                <v-tooltip :text="String(id)">
                                    <template v-slot:activator="{ props }">
                                        <h4 v-bind="props"> {{ title }}</h4>
                                        <v-chip v-if="target_query" style="font-weight: bold; font-size: 12px" class="mt-1">RC : {{target_query}} </v-chip>
                                    </template>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                    <v-col v-else>
                        <v-row style="min-height: 85px;" justify=center align="center">
                            <v-col cols="" align=center>
                                <img :src="image_path" height="60" cover
                                    style="border-radius: 5px;border: solid lightgray 1px;" />
                                <div>
                                    <v-chip
                                        style="background-color: transparent ;color:black;border: solid 3px var(--C1);font-weight: bold;border-radius: 11px;"
                                        variant="flat">
                                        <h5>
                                            {{ creator }}
                                        </h5>
                                    </v-chip>
                                </div>
                            </v-col>
                            <v-col cols="" style="font-weight: bold;" class="" align="left">
                                <div class="pb-2"> {{ title }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-col>
                <v-col v-else align="left" style="">
                    <v-row align="center" justify="left">
                        <v-col :cols="isMobileXs ? '' : 'auto'" align=center>
                            <v-progress-circular :size="70" width="2" color="var(--C1)" indeterminate
                                :class="isMobileXs ? 'ml-3' : 'ml-8'">
                            </v-progress-circular>
                            <div class="ml-3 mt-2" v-if="isMobileXs">
                                <v-chip
                                    style="background-color: transparent ;color:black;border: solid 3px var(--C1);font-weight: bold;border-radius: 11px;"
                                    variant="flat">
                                    <h5>
                                        {{ creator }}
                                    </h5>
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col cols="" align=left :class="isMobileXs ? '' : 'ml-5'">
                            <h4>
                                {{ title }} <br> <span style="color:red">En cours de génération</span>
                            </h4>
                        </v-col>
                        <!-- <v-col align="left" cols="auto" class="pl-0">
                            <h4>
                                {{ title }} - <span style="color:red">En cours de génération</span>
                            </h4>
                        </v-col> -->
                    </v-row>
                </v-col>
            </template>
            <template #item-creator="{ creator }">
                <v-col align="center">
                    <v-chip
                        style="background-color: transparent ;color:black;border: solid 3px var(--C1);font-weight: bold;border-radius: 11px;"
                        variant="flat">
                        <h5>
                            {{ creator }}
                        </h5>
                    </v-chip>
                </v-col>
            </template>
            <template
                #item-publication_timestamp="{ publication_timestamp, publication_time, isPosted, post_compte_token }">
                <v-col>
                    <div class="py-2" :style="{ 'color': isPosted == 2 ? 'red' : 'green' }"
                        style="font-size: 13px; font-weight: bold;">

                        <span v-if="isPosted == 1">
                            <span> <v-icon v-if="isPosted == 2" size="25"> mdi-pause-circle-outline</v-icon><v-icon
                                    v-else size="25">mdi-check-circle-outline </v-icon> </span>
                            Posté le :
                            {{ formatDate(publication_timestamp * 1000, 'DD/MM/YYYY') }}
                            {{ publication_time.split(':')[0] }}:
                            {{ publication_time.split(':')[1] }}
                        </span>
                        <div
                            v-else-if="isPosted == 0 && groupInformation.post_month >= groupInformation.post_month_max && formatDate(publication_timestamp * 1000, 'MM') == formatDate(undefined, 'MM')">
                            <v-chip style="padding:40px 25px;font-weight: bold;" color="red">
                                Nombre de publication pour le mois en cours atteintes, <br> nous vous invitons à
                                reprogrammer
                                votre publication ! <br>
                                ( {{ formatDate(publication_timestamp * 1000, 'DD/MM/YYYY') }}
                                {{ publication_time.split(':')[0] }}:
                                {{ publication_time.split(':')[1] }} )
                            </v-chip>
                        </div>
                        <div v-else>
                            <div v-if="post_compte_token && post_compte_token.length > 0">
                                <span> <v-icon v-if="isPosted == 2" size="25"> mdi-pause-circle-outline</v-icon><v-icon
                                        v-else size="25">mdi-check-circle-outline </v-icon> </span>
                                Programmé pour le :
                                {{ formatDate(publication_timestamp * 1000, 'DD/MM/YYYY') }}
                                {{ publication_time.split(':')[0] }}:
                                {{ publication_time.split(':')[1] }}
                            </div>
                            <div v-else>
                                Article non programmé
                            </div>
                        </div>
                        <!-- ---
                    {{ formatDate(publication_timestamp * 1000, 'MM') }}
                    {{ formatDate(undefined, 'MM') }}
                    ---
                    {{ (groupInformation.post_month) }} /
                    {{ groupInformation.post_month_max }} -->

                    </div>
                </v-col>
            </template>
            <template #item-creation_date="{ creation_date, isPosted }">
                <v-col :style="{ 'color': isPosted == '2' ? 'red' : 'green' }" style="font-weight: bold;">
                    {{ getCreationDate(creation_date) }}
                </v-col>
            </template>
        </Vue3EasyDataTable>
    </v-card>
    <v-snackbar v-model="snackData.model" :color="snackData.color">
        <div style="font-weight: bold;color:white">
            {{ snackData.label }}
        </div>
    </v-snackbar>
</template>

<script>

import engine from '@/core/index'
import editShopifyArticle from '@/components/blog/blog-article-edition.vue'
// import editShopifyArticle from '@/components/shopify/shopify-edition-funct.vue'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import moment from 'moment';
import { ref } from 'vue'

export default {
    setup() {
        const tmp_searh_value = ref(undefined)
        const showCloseBtn = ref(false)
        const modalIsOpen = ref({})
        const modalData = ref({})
        const snackData = ref({})

        const tab_filter = ref({
            creator: { key: 'creator', label: 'Créateur', show: true, value: undefined },
            date: { key: 'date', label: 'Publication', show: true, value: undefined },
            date_tmp: { key: 'date_tmp', label: 'date_tmp', show: false, value: undefined },
            sort_ascend: { key: 'sort_ascend', label: 'sort_ascend', show: false, value: true }
        })
        const selected_articles = ref([])

        const openSnackBar = function (label, color) {
            snackData.value = {
                label,
                model: true,
                color
            }
        }

        const openProgrammation = function (item) {
            if (item.description && item.description.length > 0) {
                showCloseBtn.value = false
                modalData.value = {
                    data: formatDataFromBDD(item),
                    publication_id: item.publication_id
                }
                // if (item.platform == 'Instagram')
                //     modalIsOpen.value['editionModal'] = true
                // if (item.platform == 'Shopify')
                modalIsOpen.value['editShopifyArticle'] = true
            } else {
                openSnackBar("Génération de votre article est en cours, temps estimé entre 5 et 9 minutes.", "#EF5350")
            }
        }

        const formatDataFromBDD = function (data) {
            let formatedItem = {}
            for (const [key, value] of Object.entries(data)) {
                if (key) {
                    if (key == "publication_time") {
                        formatedItem[key] = value.split(':')[0] + ':' + value.split(':')[1]
                    } else {
                        formatedItem[key] = value
                    }
                }
            }
            return formatedItem
        }

        return { tmp_searh_value, tab_filter, selected_articles, openProgrammation, modalData, modalIsOpen, openSnackBar, snackData }
    },
    props: {
        updateTableAndCloseEditionModal: { type: Function },
        updateData: { type: Function },
        formatDate: { type: Function },
        setValue: { type: Function },
        set_search: { type: Function },
        getOperatingSystem: {},
        blogListe: {},
        userInformation: {},
        groupInformation: {},
        user_squeduel_liste: {},
        windowHeight: {},
        selectedView: {},
        showPostInfo: {},
        getCurrentSelectedDataView: {},
        getUser_squeduel_liste: {},
    },
    components: {
        Vue3EasyDataTable,
        editShopifyArticle
    },
    watch: {
        tmp_searh_value(val) {
            if (val == undefined || val.length == 0) {
                this.set_search(undefined)
            }
        }
    },
    computed: {
        isMobileXs() {
            return this.$vuetify.display.xs
        },
        isMobile() {
            return this.$vuetify.display.mobile
        },
        headers() {
            let tab = [
                { text: this.isMobileXs ? 'Article' : "Titre de l’article de blog", value: "id", width: "0px", sortable: true },
            ]

            if (!this.isMobileXs) {
                tab.push({ text: "Créateur", value: "creator", width: "0px", sortable: true })
                if (this.showPostInfo)
                    tab.push({ text: this.isMobileXs ? 'Publication' : "Date de publication", value: "publication_timestamp", width: "0px", sortable: true })
                else
                    tab.push({ text: this.isMobileXs ? 'Création' : "Date de création", value: "creation_date", width: "0px", sortable: true })
            }

            return tab
        },
    },
    methods: {
        updateTableAndCloseEditionModalLocal() {
            this.closeModal()
            this.updateTableAndCloseEditionModal()
        },
        closeModal() {
            // closeModal(data) {
            this.modalIsOpen = {}
            // if (data) {
            //     this.snackData = data
            // }
        },
        getCreationDate(date) {
            date = String(date)
            return moment(date).format('DD/MM/YYYY HH:mm')
            // return this.formatDate(
            //     date.slice(0, 4) + '-' +
            //     date.slice(4, 6) + '-' +
            //     date.slice(6, 8) + ' ' +
            //     date.slice(8, 10) + ':' +
            //     date.slice(10, 12)
            //     , 'LLL')
        },
        deleteSelectedArticle() {
            let that = this
            for (let i = 0; i < this.selected_articles.length; i++) {
                engine.edit_article_from_id_by_key(this.selected_articles[i].publication_id, 'publication_delete', 1, function () {
                    if (i + 1 == that.selected_articles.length) {
                        that.selected_articles = []
                        that.updateData()
                    }
                })
            }
        },
        reprogrameSelectedArticle() {
            let that = this
            for (let i = 0; i < this.selected_articles.length; i++) {
                engine.edit_article_from_id_by_key(this.selected_articles[i].publication_id, 'isPosted', 0, function () {
                    if (i + 1 == that.selected_articles.length) {
                        that.selected_articles = []
                        that.updateData()
                    }
                })
            }
        },
        deprogrameSelectedArticle() {
            let that = this
            for (let i = 0; i < this.selected_articles.length; i++) {
                engine.edit_article_from_id_by_key(this.selected_articles[i].publication_id, 'isPosted', 2, function () {
                    if (i + 1 == that.selected_articles.length) {
                        that.selected_articles = []
                        that.updateData()
                    }
                })
            }
        },
        publishNowSelectedArticle() {

            let that = this

            let date = moment().format('YYYY-MM-DD')
            let time = moment().format('HH:mm') + ':00'
            let timestamp = moment(date + ' ' + time).format('x')

            let newDataTime = {
                isPosted: 0,
                publication_date: date,
                publication_time: time,
                publication_timestamp: parseInt(timestamp) / 1000,
                publication_datetime: date + ' ' + time
            }
            for (let i = 0; i < this.selected_articles.length; i++) {
                engine.edit_article_from_id_by_multie_keys(this.selected_articles[i].publication_id, newDataTime, function () {
                    if (i + 1 == that.selected_articles.length) {
                        that.selected_articles = []
                        that.updateData()
                    }
                })
            }
        },
        setSearch() {
            this.set_search(this.tmp_searh_value)
        }
    }
}
</script>

<style></style>