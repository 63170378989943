<template>
    <v-dialog v-model="modalIsOpen['configIsOpen']" class="local-scrollbar" persistent width="100%" max-width="1400"
        :fullscreen="isMobile">
        <v-card class="bg">
            <configuration-view class="" :getLinksFormInformation="getLinksFormInformation"
                :closeModal="function () { modalIsOpen['configIsOpen'] = false }" :userInformation="userInformation"
                :getUserData="getUserData" :groupInformation="groupInformation"
                :blogListe="blogListe"></configuration-view>
        </v-card>
    </v-dialog>
    <v-col class="">
        <v-row style="height: 91.5vh;">
            <v-col cols="12" md="12" lg="8" class="">
                <v-card variant="flat" style="overflow: auto;border-radius: 0px;"
                    :color="showAllPage ? '#ECEFF1' : 'transparent'">
                    <BlogArticleCreationForm v-if="!isLoading && groupInformation && selectedSite"
                        :setIsLoading="setIsLoading" :showAllPage="showAllPage" :openConfig="openConfig"
                        :userInformation="userInformation" :getOperatingSystem="getOperatingSystem"
                        :getLinksFormInformation="getLinksFormInformation" :validationFunction="valideArticlecreation"
                        validationLabel="Générer" :localDataForm="dataForm" :pictureSrcIsLoading="undefined"
                        :siteInfoListe="siteInfoListe" :isPosted="false" :selectedBlog="selectedBlog"
                        :groupInformation="groupInformation" :getUserData="getUserData" :windowHeight="windowHeight"
                        :blogListe=blogListe :setLocalDataFrom="setLocalDataFrom" :setSelectedSite="setSelectedSite"
                        :selectedSite="selectedSite" :updateData="updateData" :changeTablePage="changeTablePage"
                        :getSiteListe="getSiteListe" :siteListe="siteListe">
                    </BlogArticleCreationForm>
                    <v-col v-else cols="11" sm="4" md="4" lg="12">
                        <v-row style="height: 100vh;" justify="center" align="center">
                            <v-col>
                                <basic-loading></basic-loading>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
            <v-col v-if="!isLittleScreen" style="position:relative" class="">
                <v-card class="" variant="flat" style="border-radius: 2px;" align=center>
                    <video width="100%" controls>
                        <source
                            src="https://manager.jauris.com/medias/public/Cr%C3%A9ation%20d'un%20article%20de%20blog%20avec%20Jauris.mp4"
                            type="video/mp4" style="width:100%;height:320px;">
                    </video>
                    <!-- <iframe
                        src="https://manager.jauris.com/medias/public/Cr%C3%A9ation%20d'un%20article%20de%20blog%20avec%20Jauris.mp4?autoplay=0"
                        frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                        style="width:100%;height:320px;">
                    </iframe> -->
                    <div style="font-weight: bold;color:var(--C1);font-size: 21px;" class="pa-2 pb-4">Comment générer un
                        article avec Jauris ?
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>

import { ref, onMounted } from 'vue'
import moment from 'moment'
import BlogArticleCreationForm from "./blog-article-creation-form.vue"
import engine from '@/core/index'
import BasicLoading from '@/components/basic/basic-loading.vue'
import ConfigurationView from '@/view/view-configuration.vue'

// import _ from 'lodash';

export default {
    props: {
        windowHeight: {},
        groupInformation: {},
        selectedBlog: {},
        getOperatingSystem: {},
        setIsLoading: { type: Function },
        changeTablePage: { type: Function },
        closeFunction: { type: Function },
        updateData: { type: Function },
        getUserData: { type: Function },
        getLinksFormInformation: {},
        userInformation: { required: true },
    },
    components: {
        BlogArticleCreationForm,
        BasicLoading,
        ConfigurationView
    },
    setup(props) {
        // setup() {
        const isLoading = ref(true)
        const showAllPage = ref(false)
        const defaultDate = ref(moment().add(24, 'h').set('h', 12).set('m', 25))
        const selectedSite = ref(undefined)
        const siteInfoListe = ref([])
        const blogListe = ref({})
        const siteListe = ref([])
        const selectedTags = ref([])

        const dataForm = ref({
            user: { key: 'user', isValide: undefined, value: {}, label: 'User', autoGeneration: false, isRequired: true, },
            group: { key: 'group', isValide: undefined, value: {}, label: 'Group', autoGeneration: false, isRequired: true, },
            platform: { key: 'platform', isValide: undefined, value: undefined, label: 'Platforme', autoGeneration: false, isRequired: true, },
            online_platform_uuid: { key: 'online_platform_uuid', isValide: undefined, value: undefined, label: 'online_platform_uuid', autoGeneration: false, isRequired: true, },
            // platform: { key: 'platform', isValide: undefined, value: 'Shopify', label: 'Platforme', autoGeneration: false, isRequired: true, },
            thread: { key: 'thread', isValide: undefined, value: undefined, label: 'Thread', autoGeneration: false, isRequired: true },
            title: { key: 'title', isValide: undefined, value: undefined, label: 'Thème de l\'article', autoGeneration: true, isRequired: true },
            publication_id: { key: 'publication_id', isValide: undefined, value: undefined, label: 'Poste ID', autoGeneration: false, isRequired: false },
            optionalContent: { key: 'optionalContent', isValide: undefined, value: {}, label: 'Contenue optionel', autoGeneration: false, isRequired: false },
            tags: { key: 'tags', isValide: undefined, value: undefined, label: 'Tags', autoGeneration: false, isRequired: false },
            image_resume: { key: 'image_resume', isValide: undefined, value: undefined, label: 'image_resume', autoGeneration: false, isRequired: false },
            publication_date: { key: 'publication_date', isValide: undefined, value: moment(defaultDate.value).format("MM/DD/YYYY"), label: 'Date de publication', autoGeneration: false, isRequired: true },
            publication_time: { key: 'publication_time', isValide: undefined, value: moment(defaultDate.value).format("HH") + ':' + moment().add(5, 'm').format("mm"), label: 'Heure de publication', autoGeneration: false, isRequired: true },
            publication_datetime: { key: 'publication_datetime', isValide: undefined, value: undefined, label: undefined, autoGeneration: false, isRequired: false },
            image: { key: 'image', isValide: undefined, value: undefined, label: 'Photo', autoGeneration: false, isRequired: false },
            image_path: { key: 'image_path', isValide: undefined, value: undefined, label: 'Photo', autoGeneration: true, isRequired: true },
            post_compte_id: { key: 'post_compte_id', isValide: undefined, value: undefined, label: 'Blog de publication', autoGeneration: false, isRequired: true },
            html: { key: 'html', isValide: undefined, value: undefined, label: 'html', autoGeneration: false, isRequired: true },
            key_words: { key: 'key_words', isValide: undefined, value: undefined, label: 'Mot clef cible de l\'article', autoGeneration: false, isRequired: false },
            links_redirect: { key: 'links_redirect', isValide: undefined, value: undefined, label: 'Lien de redirection', autoGeneration: false, isRequired: false },
            metadata: { key: 'metadata', isValide: undefined, value: undefined, label: 'Meta description', autoGeneration: false, isRequired: false },
            author: { key: 'author', isValide: undefined, value: undefined, label: 'Nom de l\'auter', autoGeneration: false, isRequired: false },
            isPosted: { key: 'isPosted', isValide: undefined, value: undefined, label: '', autoGeneration: false, isRequired: false },
            isOptimized: { key: 'isOptimized', isValide: undefined, value: false, label: 'Laisser jauris optimiser mon intention de recherche', autoGeneration: false, isRequired: true },
        })

        const setLocalDataFrom = function (data) {
            dataForm.value = data
        }

        const setSelectedSite = function (data) {
            if (selectedSite.value == undefined || data.uuid != selectedSite.value.uuid)
                isLoading.value = true
            selectedSite.value = data

            blogListe.value = {}
            siteInfoListe.value = []
            // for (let i = 0; i < siteListe.value.length; i++) {
            if (selectedSite.value && selectedSite.value.platform == 'shopify') {
                engine.get_shopify_blog_liste(selectedSite.value.url, selectedSite.value.compte_token, function (data) {
                    if (data.state == 200) {
                        let blogs = data.data.blogs
                        console.log("blog", blogs);

                        for (let a = 0; a < blogs.length; a++) {
                            if (blogListe.value[selectedSite.value.label] == undefined) {
                                siteInfoListe.value.push(selectedSite.value)
                                blogListe.value[selectedSite.value.label] = []
                            }
                            let tmp = { ...blogs[a], ...selectedSite.value }
                            tmp['value'] = selectedSite.value.id
                            blogListe.value[selectedSite.value.label].push(tmp)
                        }
                        if (blogListe.value[selectedSite.value.label]) {
                            if (dataForm.value['isPosted'].value == undefined) {
                                dataForm.value['post_compte_id'].value = blogListe.value[selectedSite.value.label][0]
                            }
                            else {
                                dataForm.value['post_compte_id'].value = blogListe.value[selectedSite.value.label].find((val) => val.id == dataForm.value['post_compte_id'].value)
                            }
                        }
                    }
                    console.log("blogListe", blogListe.value);
                    // props.updateData()
                    // props.getUserData()
                    isLoading.value = false
                })
            }
            else {
                // props.updateData()
                // props.getUserData()
                setTimeout(() => {
                    isLoading.value = false
                }, 1000);
            }
            // }
        }

        const getSiteListe = function () {
            siteListe.value = []

            for (const value of Object.values(props.groupInformation.web_site_data))
                for (let i = 0; i < value.length; i++)
                    siteListe.value.push(value[i])

            if (selectedSite.value == undefined)
                setSelectedSite(siteListe.value[0])
            else
                setSelectedSite(siteListe.value.find((val) => val.uuid == selectedSite.value.uuid))
        }

        onMounted(() => {
            getSiteListe()
            if (dataForm.value['tags'] && dataForm.value['tags'].value)
                selectedTags.value = dataForm.value['tags'].value.split(',')
        })


        return {
            isLoading,
            dataForm,
            showAllPage,
            selectedSite,
            blogListe,
            siteInfoListe,
            setLocalDataFrom,
            setSelectedSite,
            siteListe,
            getSiteListe
        }
    },
    computed: {
        isLittleScreen() {
            return this.$vuetify.display.mdAndDown
        },
        isMobileXs() {
            return this.$vuetify.display.xs
        },
        isMobile() {
            return this.$vuetify.display.mobile
        },
    },
    data() {
        return {
            modalIsOpen: {},
        }
    },
    methods: {
        openConfig() {
            this.modalIsOpen['configIsOpen'] = true
        }
    }
}
</script>

<style></style>