<template>
    <div v-if="(isLoading)">
        <basic-loading></basic-loading>
    </div>
    <v-card v-else variant="flat" style="background-color: white; height: 100vh;">
        <div style="overflow: auto;" class="local-scrollbar"
            :style="{ 'height': isMobile ? getOperatingSystem == 'IOS' ? '89vh' : '93vh' : windowHeight + 'px' }">
            <article-form :lighthouseHtml="lighthouseHtml" :getOperatingSystem="getOperatingSystem"
                :closeModal="closeModal" :userInformation="userInformation" :localDataForm="localDataForm"
                :validationFunction="editShopifyPostById" validationLabel="Enregistrer" :setIsLoading="setIsLoading"
                :pictureSrcIsLoading="undefined" :isPosted="isPosted" :groupInformation="groupInformation"
                :selectedSite="selectedSite" :siteInfoListe="siteInfoListe" :selectedTags="selectedTags"
                :blogListe="blogListe"></article-form>
        </div>
    </v-card>
</template>

<script>
import { ref, onMounted } from 'vue'
// import { ref,computed, onMounted } from 'vue'
import moment from 'moment'
import engine from '@/core/index'
import ArticleForm from "./blog-article-edition-form.vue"
import { v4 as uuidv4 } from 'uuid';
import BasicLoading from '@/components/basic/basic-loading.vue'

export default {
    props: {
        getOperatingSystem: {},
        windowHeight: {},
        closeFunction: { type: Function },
        userInformation: {},
        dataForm: {},
        closeModal: {},
        siteListe: {},
        groupInformation: {},
    },
    components: {
        ArticleForm,
        BasicLoading
    },
    data() {
        return {
            lighthouseHtml: undefined,
        }
    },
    mounted() {
        // console.log("htmlContent", this.htmlContent);
        let that = this
        fetch('https://manager-test.jauris.com/medias/report.txt')
            .then(response => response.text())
            .then(html => {
                that.lighthouseHtml = html
            });
    },
    setup(props) {
        const imgUrl = ref("https://" + process.env.VUE_APP_USE_URL + "/medias/")
        const isLoading = ref(true)
        const isPosted = ref(undefined)
        const pictureSrcIsLoading = ref(false)
        const selectedSite = ref(undefined)
        const siteInfoListe = ref([])
        const blogListe = ref(undefined)
        const selectedTags = ref([])

        const localDataForm = ref({
            platform: { key: 'platform', isValide: undefined, value: '', label: 'Platforme', autoGeneration: false, isRequired: true, },
            online_platform_uuid: { key: 'online_platform_uuid', isValide: undefined, value: undefined, label: 'online_platform_uuid', autoGeneration: false, isRequired: true, },
            description: { key: 'description', isValide: undefined, value: undefined, label: 'Description', autoGeneration: false, isRequired: true },
            title: { key: 'title', isValide: undefined, value: undefined, label: 'Titre de l\'article', autoGeneration: true, isRequired: true },
            publication_id: { key: 'publication_id', isValide: undefined, value: undefined, label: 'Poste ID', autoGeneration: false, isRequired: false },
            image_resume: { key: 'image_resume', isValide: undefined, value: undefined, label: 'image_resume', autoGeneration: false, isRequired: false },
            optionalContent: { key: 'optionalContent', isValide: undefined, value: {}, label: 'Contenue optionel', autoGeneration: false, isRequired: false },
            // blogId: { key: 'blogId', isValide: undefined, value: undefined, label: 'Blog ID', autoGeneration: false, isRequired: false },
            tags: { key: 'tags', isValide: undefined, value: undefined, label: 'Tags', autoGeneration: false, isRequired: false },
            publication_date: { key: 'publication_date', isValide: undefined, value: moment().format("MM/DD/YYYY"), label: 'Date de publication', autoGeneration: false, isRequired: true },
            publication_time: { key: 'publication_time', isValide: undefined, value: moment().format("HH") + ':' + moment().add(5, 'm').format("mm"), label: 'Heure de publication', autoGeneration: false, isRequired: true },
            // compte: { key: 'compte', isValide: undefined, value: undefined, label: 'Blog de publication', autoGeneration: false, isRequired: true },
            publication_datetime: { key: 'publication_datetime', isValide: undefined, value: undefined, label: undefined, autoGeneration: false, isRequired: false },
            // image: { key: 'image', isValide: undefined, value: undefined, label: 'Photo', autoGeneration: false, isRequired: false },
            image_path: { key: 'image_path', isValide: undefined, value: undefined, label: 'Photo', autoGeneration: true, isRequired: true },
            post_compte_id: { key: 'post_compte_id', isValide: undefined, value: undefined, label: 'Blog de publication', autoGeneration: false, isRequired: false },
            key_words: { key: 'key_words', isValide: undefined, value: undefined, label: 'Mot clef cible de l\'article', autoGeneration: false, isRequired: false },
            links_redirect: { key: 'links_redirect', isValide: undefined, value: undefined, label: 'Lien de redirection', autoGeneration: false, isRequired: false },
            metadata: { key: 'metadata', isValide: undefined, value: undefined, label: 'Meta description', autoGeneration: false, isRequired: false },
            author: { key: 'author', isValide: undefined, value: undefined, label: 'Nom de l\'auter', autoGeneration: false, isRequired: false },
            isPosted: { key: 'isPosted', isValide: undefined, value: undefined, label: '', autoGeneration: false, isRequired: false },
        })

        const editShopifyPostById = function (data) {
            isLoading.value = true

            if (data.publication_datetime.value.includes('T'))
                data.publication_datetime.value = data.publication_datetime.value.replace('T', ' ')
            let date = data.publication_datetime.value.split(' ')[0]
            let time = data.publication_datetime.value.split(' ')[1]
            let timestamp = moment(data.publication_datetime.value).format('x')
            let resume_photo_id = uuidv4()
            let postData = {}

            for (const [key, value] of Object.entries(data)) {
                postData[key] = value.value
            }

            postData['group_id'] = props.groupInformation.id
            postData['publication_date'] = date
            postData['publication_time'] = time
            postData['publication_timestamp'] = parseInt(timestamp) / 1000
            postData['publication_datetime'] = data.publication_datetime.value
            // postData['publication_datetime'] = date

            postData['post_compte_token'] = selectedSite.value.compte_token
            postData['site_url'] = selectedSite.value.url
            // postData['platform'] = props.dataForm.platform
            postData['blog_id'] = selectedSite.value.blog_id

            if (postData['post_compte_id'] && postData['post_compte_id'].id)
                postData['post_compte_id'] = postData['post_compte_id'].id
            else
                postData['post_compte_id'] = undefined

            postData['publication_id'] = props.dataForm.publication_id

            postData['optionalContent']['medias'] = {}
            postData['optionalContent']['medias'].resumePhoto = {}
            postData['optionalContent'].medias.resumePhoto.fullUrl = imgUrl.value + props.userInformation.user_id + '/' + postData['publication_id'] + '/' + resume_photo_id + '.webp'
            postData['optionalContent'].medias.resumePhoto.imgfullId = props.userInformation.user_id + '/' + postData['publication_id'] + '/' + resume_photo_id + '.webp'
            postData['optionalContent'].medias.resumePhoto.src = postData['image_resume']
            // postData['optionalContent'].medias.mainPhoto.fullUrl = imgUrl.value + props.userInformation.user_id + '/' + postData['publication_id'] + '/' + postData['optionalContent'].medias.mainPhoto.id + '.webp'
            // postData['optionalContent'].medias.mainPhoto.imgfullId = props.userInformation.user_id + '/' + postData['publication_id'] + '/' + postData['optionalContent'].medias.mainPhoto.id + '.webp'


            engine.edit_shopify_post_by_id(
                postData,
                function () {
                    // if (data.state == 200) {
                    props.closeFunction()
                    // }
                }
            )
        }

        const setIsLoading = function (value) {
            isLoading.value = value
        }

        const getBlogListe = function () {

            // if (blogListe.value == undefined)
            //     blogListe.value = {}

            for (let i = 0; i < props.siteListe.length; i++) {

                if (props.siteListe[i].platform == 'shopify' && localDataForm.value.platform.value == 'Shopify')
                    engine.get_shopify_blog_liste(props.siteListe[i].url, props.siteListe[i].compte_token, function (data) {
                        let localBlogListe = {}
                        if (data.state == 200) {
                            let blogs = data.data.blogs
                            for (let a = 0; a < blogs.length; a++) {
                                if (localBlogListe[props.siteListe[i].label] == undefined) {
                                    siteInfoListe.value.push(props.siteListe[i])
                                    localBlogListe[props.siteListe[i].label] = []
                                }
                                let tmp = { ...blogs[a], ...props.siteListe[i] }
                                tmp['value'] = props.siteListe[i].id
                                localBlogListe[props.siteListe[i].label].push(tmp)
                            }
                        }
                        blogListe.value = localBlogListe
                        if (i + 1 == props.siteListe.length)
                            isLoading.value = false

                    })
            }
        }


        onMounted(() => {
            for (const [key, value] of Object.entries(props.dataForm.data)) {
                if (localDataForm.value[key]) {
                    localDataForm.value[key].value = value
                }
            }
            localDataForm.value = JSON.parse(JSON.stringify(localDataForm.value))
            localDataForm.value.description.value = localDataForm.value.description.value.replace(/\s{2,}/g, ' ')

            selectedSite.value = props.siteListe.find((val) => val.uuid == localDataForm.value.online_platform_uuid.value)

            if (selectedSite.value == undefined) {
                selectedSite.value = props.siteListe[0]
                localDataForm.value.online_platform_uuid.value = props.siteListe[0].uuid
            }

            if (selectedSite.value.compte_token == undefined || selectedSite.value.compte_token.length == 0)
                localDataForm.value['post_compte_id'].isRequired = false

            getBlogListe()
            if (localDataForm.value['tags'] && localDataForm.value['tags'].value)
                selectedTags.value = localDataForm.value['tags'].value.split(',')

            if (localDataForm.value.isPosted.value === 1) {
                isPosted.value = true
            } else
                isPosted.value = false

            isLoading.value = false
        })

        return {
            editShopifyPostById,
            setIsLoading,
            isLoading,
            localDataForm,
            pictureSrcIsLoading,
            isPosted,
            selectedSite,
            siteInfoListe,
            blogListe,
            selectedTags,
        }
    },
    watch: {
        isLoading(val) {
            if (val == false) {
                this.$emit('showCloseBtn')
            }
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.display.mobile
        },
        isMobileXs() {
            return this.$vuetify.display.xs
        },

    },
}

</script>

<style></style>