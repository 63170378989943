<template>
  <v-card v-if="user_squeduel_liste" :class="isMobile ? '' : 'px-2 py-2'" variant="flat" style="border-radius: 0px;"
    color="transparent">
    <div v-if="!isLoadingPlan">
      <v-col v-if="isLittleScreen == false" :class="isMobile ? 'pl-9' : ''" class="pb-0">
        <v-row>
          <v-col v-for="elem in mainFilterType" :key="elem.label" cols="auto" class="px-0"
            :class="isMobile ? '' : 'pt-0 pr-1'">
            <v-btn @click="selectedView = elem.selectedView" class="tab-menu px-8 pt-1"
              :style="selectedView == elem.selectedView ? { 'background-color': 'var(--C1)', 'color': 'white' } : { 'background-color': '#D9D9D9', 'color': 'black' }"
              variant="flat" style="font-size: 11px;;">
              {{ elem.label }} <span v-if="elem.selectedView != 3" class="ml-1">({{ runFunctionLength(elem.function)
                }})</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else class=" px-0 py-0">
        <v-card class="px-3 " style="border-radius: 0px; background: var(--BG);" variant="flat">
          <v-select style="border-radius: 5px !important;" :items="mainFilterType" item-value="selectedView"
            item-title="label" v-model="selectedView" variant="solo" bg-color="var(--C4)" hide-details></v-select>
        </v-card>
      </v-col>
    </div>
    <div v-if="selectedView != 3" class="pa-2 pb-1 bg" style="position:relative;border-radius: 5px;width: 100%;"
      :style="{ 'border-top-left-radius': selectedView == 0 ? '0px' : '5px', 'border-top-right-radius': isLittleScreen ? '0px' : 'auto', 'border-top-left-radius': isLittleScreen ? '0px' : '' }">
      <blogArticleTable :getOperatingSystem="getOperatingSystem"
        :updateTableAndCloseEditionModal="updateTableAndCloseEditionModal" :groupInformation="groupInformation"
        :selectedBlog="selectedBlog" :updateData="updateData" :formatDate="formatDate"
        :user_squeduel_liste="user_squeduel_liste" :windowHeight="windowHeight" :selectedView="selectedView"
        :showPostInfo="showPostInfo" :userInformation="userInformation"
        :getCurrentSelectedDataView="getCurrentSelectedDataView" :getUser_squeduel_liste="getUser_squeduel_liste"
        :blogListe="blogListe" :isMobile="isMobile" :set_search="set_search">
      </blogArticleTable>
    </div>
    <v-col v-else class="bg" cols="12" :style="{
      'height': isMobile ? '' : 'calc(100vh - 132px)',
      'border-radius': '0px', 'border-top-left-radius': '0px'
    }">
      <blogArticleCreation :setIsLoading="setIsLoading" :getOperatingSystem="getOperatingSystem"
        :getLinksFormInformation="getLinksFormInformation" :windowHeight="windowHeight" :selectedBlog="selectedBlog"
        :userInformation="userInformation" :groupInformation="groupInformation" :getUserData="getUserData"
        :updateData="updateData" :blogListe="blogListe" :closeModal="closeModal" :changeTablePage="changeTablePage">
      </blogArticleCreation>
    </v-col>
  </v-card>
  <v-col v-else :is-hide-paging="true" align="center">
    <basic-loading></basic-loading>
  </v-col>
  <v-snackbar v-model="snackData.model" :color="snackData.color" timeout="15000" style="font-weight: bold;">
    <!-- <v-col style="font-weight: bold;color:white;font-size: 19px;"> -->
      {{ snackData.label }}
    <!-- </v-col> -->
  </v-snackbar>
</template>

<script>
import moment from 'moment';
import { ref } from 'vue'
import basicLoading from '@/components/basic/basic-loading.vue';
import blogArticleCreation from '@/components/blog/blog-article-creation.vue'
import blogArticleTable from '@/components/blog/blog-article-table.vue'

export default {
  props: {
    getOperatingSystem: {},
    getLinksFormInformation: {},
    blogListe: {},
    isLoadingPlan:{},
    updateTableAndCloseEditionModal: { type: Function },
    closeModal: { type: Function },
    setIsLoading: { type: Function },
    getUserData: { type: Function },
    set_search: { type: Function },
    updateData: { type: Function },
    openModalCreation: { type: Function },
    selectedBlog: {},
    userInformation: {},
    user_squeduel_liste: {},
    windowHeight: {},
    planificateurElements: {},
    groupInformation: {}
  },
  setup() {
    const selectedView = ref(3)
    const snackData = ref({})

    const changeTablePage = function (num, message, color) {
      selectedView.value = num
      openSnackBar(message, color)
    }

    const openSnackBar = function (label, color) {
      snackData.value = {
        label,
        model: true,
        color
      }
    }

    return { selectedView, changeTablePage, snackData }
  },
  mounted() {
    moment.locale('fr');
  },
  computed: {
    showPostInfo() {
      let ret = false

      if (this.blogListe && this.blogListe.find((val) => val.compte_token && val.compte_token.length > 0))
        ret = true
      return ret
    },
    getCurrentSelectedDataView() {
      let that = this
      let data = this.runFunction(this.mainFilterType.find((val) => val.selectedView == that.selectedView).function)

      return data
    },
    mainFilterType() {
      let typeListe = [
        {
          label: 'Génération',
          selectedView: 3,
          valueView: 3,
          function: 'getUser_squeduel_liste_brouillon',
        }
      ]

      if (this.showPostInfo) {
        typeListe.push(
          {
            label: 'En attente',
            selectedView: 0,
            function: 'getUser_squeduel_liste_not_posted',
          },
          // {
          //   label: 'Non programmé',
          //   selectedView: 2,
          //   function: 'getUser_squeduel_liste_brouillon',
          // },
          {
            label: 'Publié',
            selectedView: 1,
            function: 'getUser_squeduel_liste_posted',
          }
        )
      } else {
        typeListe.push(
          {
            label: 'Articles',
            selectedView: 0,
            function: 'getUser_squeduel_liste_all',
          },
          {
            label: 'Archive',
            selectedView: 2,
            function: 'getUser_squeduel_liste_archived',
          },
        )
      }

      return typeListe
    },
    getUser_squeduel_liste() {
      if (this.user_squeduel_liste == undefined)
        return []

      let sortedData = JSON.parse(JSON.stringify(this.user_squeduel_liste))

      sortedData.sort(function (a, b) {
        return b.id - a.id;
      })

      return sortedData
    },
    getUser_squeduel_liste_archived() {
      if (this.user_squeduel_liste == undefined)
        return []
      let sortedData = JSON.parse(JSON.stringify(this.user_squeduel_liste))

      sortedData = sortedData.filter((val) => val.isPosted == 2)

      return sortedData
    },
    getUser_squeduel_liste_all() {
      if (this.user_squeduel_liste == undefined)
        return []
      let sortedData = JSON.parse(JSON.stringify(this.user_squeduel_liste))

      sortedData = sortedData.filter((val) => val.isPosted == 0 || val.isPosted == 1)

      if (sortedData)
        sortedData = sortedData.sort((a, b) => b.id - a.id)

      return sortedData
    },
    getUser_squeduel_liste_posted() {
      if (this.user_squeduel_liste == undefined)
        return []
      let sortedData = JSON.parse(JSON.stringify(this.user_squeduel_liste))

      sortedData = sortedData.filter((val) => val.isPosted == 1)
      if (sortedData)
        sortedData = sortedData.sort((a, b) => b.id - a.id)

      return sortedData
    },
    getUser_squeduel_liste_not_posted() {
      if (this.user_squeduel_liste == undefined)
        return []
      let sortedData = JSON.parse(JSON.stringify(this.user_squeduel_liste))
      sortedData = sortedData.filter((val) => val.isPosted == 0 || val.isPosted == 2)
      if (sortedData)
        sortedData = sortedData.sort((a, b) => b.id - a.id)

      return sortedData
    },
    getUser_squeduel_liste_brouillon() {
      if (this.user_squeduel_liste == undefined)
        return []
      let sortedData = JSON.parse(JSON.stringify(this.user_squeduel_liste))

      sortedData = sortedData.filter((val) => val.isPosted == 2)
      if (sortedData)
        sortedData = sortedData.sort((a, b) => b.id - a.id)

      return sortedData
    },
    isMobile() {
      return this.$vuetify.display.mobile
    },
    isLittleScreen() {
      return this.$vuetify.display.smAndDown
    }
  },
  methods: {
    runFunction(func) {
      return this[func]
    },
    runFunctionLength(func) {
      return this[func].length
    },
    formatDate(date, format) {
      return moment(date).local(true).format(format)
    },
  },
  components: {
    blogArticleCreation,
    basicLoading,
    blogArticleTable
  }
}
</script>

<style></style>