<template>
    <v-dialog v-model="isReportOpen" fullscreen persistent>
        <v-btn icon @click="isReportOpen = false" class="button"
            style="position:absolute; right:10px; top:2px;z-index:20;border-radius: 100% !important;" density="compact">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <lighthouse :__LIGHTHOUSE_JSON__="localLighthouseHtml"></lighthouse>
    </v-dialog>
    <v-row align=center justify="center">
        <v-col align="center" style="padding-top:25vh" cols="12" sm="6">
            <v-card class="pa-6" v-if="!isLoading">
                <v-col align=center class="mb-5" style="font-weight:bold;font-size:25px">
                    Analyse SEO
                </v-col>
                <v-text-field label="URL complet du site à analyser (https://jauris.com)" v-model="url"></v-text-field>
                <v-btn class="button_armo" :disabled="!(url && url.length > 0)" variant="flat"
                    :class="(url && url.length > 0) ? 'button' : ''" @click="verifUrl">Analyser</v-btn>
            </v-card>
            <div v-else>
                <div style="background-color:white;width:165px;border-radius:100%" class="pa-2">
                    <v-progress-circular :size="150" :width="3" color="var(--C1)" indeterminate
                        style="background-color:white;border-radius:100%;" class="">
                        Analyse en cours
                    </v-progress-circular>
                </div>
            </div>
            <v-snackbar v-model="errorData.show" color="red">
                <div align=center style="font-weight: bold">
                    {{ errorData.message }} 
                </div>
            </v-snackbar>
        </v-col>
    </v-row>
</template>
<script>

import lighthouse from '../components/lighthouse/lighthouse-report.vue';

export default {
    data() {
        return {
            url: undefined,
            isLoading: false,
            isReportOpen: false,
            localLighthouseHtml: undefined,
            errorData : {}
        }
    },
    methods: {
        async isValidURL(string) {
            const regex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
            return regex.test(string);
        },
        async verifUrl() {
            let that = this

            that.isLoading = true

            let apiKey = 'AIzaSyDednYE9kao1M0_MY6euniSVnlZX4G_d44';
            let urlRequeste = 'https://www.googleapis.com/pagespeedonline/v5/runPagespeed?category=ACCESSIBILITY&category=PERFORMANCE&category=SEO&category=BEST-PRACTICES&locale=fr&url=' + this.url + '&key=' + apiKey;

            if (await this.isValidURL(this.url)) {
                fetch(urlRequeste)
                    .then(response => response.json())
                    .then(data => {
                        that.localLighthouseHtml = data.lighthouseResult
                        that.isReportOpen = true
                        that.isLoading = false
                    })
                    .catch(error => {
                        console.error('Erreur:', error)
                        that.isLoading = false
                    });
            } else {
                that.errorData = {
                    show: true,
                    message: 'L\'URL n\'est pas valide'
                }
                that.isLoading = false
            }
        },
    },
    components: {
        lighthouse
    }
}
</script>