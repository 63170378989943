import { Image } from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/core';

const cleanURL = (url) => {
    // Supprime les espaces et les retours à la ligne dans l'URL
    return url.replace(/\s+/g, '').trim();
};

const CustomImage = Image.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            src : {
                default: null,
                parseHTML: element => {
                    // Nettoie la source récupérée de l'attribut HTML
                    const src = element.getAttribute('src');
                    return cleanURL(src);
                },
                renderHTML: attributes => {
                    // Nettoie la source avant de la rendre dans le HTML
                    return {
                        src: cleanURL(attributes.src), // Nettoie l'URL de toute mauvaise mise en forme
                    };
                },
            },
            style: {
                default: null,
                parseHTML: element => element.getAttribute('style'),
                renderHTML: attributes => {
                    return {
                        style: attributes.style || '',
                    };
                },
            },
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ['img', mergeAttributes(HTMLAttributes)];
    },
});

export default CustomImage;


// import { Image } from '@tiptap/extension-image';
// import { mergeAttributes } from '@tiptap/core';

// const CustomImage = Image.extend({
//     addAttributes() {
//         return {
//             ...this.parent?.(),
//             style : {
//                 default: null,
//                 parseHTML: element => element.getAttribute('style'),
//                 renderHTML: attributes => {
//                     return {
//                         style: attributes.style || '',
//                     };
//                 },
//             },
//         };
//     },

//     renderHTML({ HTMLAttributes }) {
//         return ['img', mergeAttributes(HTMLAttributes)];
//     },
// });

// export default CustomImage;