<template>
    <v-dialog v-model="modalIsOpen['configIsOpen']" class="local-scrollbar" persistent width="100%" max-width="1400"
        :fullscreen="isMobile">
        <v-card class="bg">
            <configuration-view class="" :getLinksFormInformation="getLinksFormInformation"
                :closeModal="function () { modalIsOpen['configIsOpen'] = false }" :userInformation="userInformation"
                :getUserData="getUserData" :groupInformation="groupInformation"
                :blogListe="blogListe"></configuration-view>
        </v-card>
    </v-dialog>
    <v-dialog v-model="modalIsOpen['helpisOpen']" width="90%" max-width="1100">
        <help-modal :close="function () { modalIsOpen['helpisOpen'] = false }"></help-modal>
    </v-dialog>
    <v-hover v-model="drawerIsHover">
        <template v-slot:default="{ props }">
            <v-navigation-drawer v-bind="props" :rail="!isMobile" expand-on-hover rail-width="105"
                v-model="drawerIsOpen" width="300" app
                style="border: none;backdrop-filter: blur(30px); background-color: rgba(255, 255, 255, 0.1);"
                class="px-4"
                :style="{ 'z-index': isMobile ? 'null' : 1, 'padding-top': isMobile ? '0px' : '100px', 'border-right': isMobile ? 'solid 1px var(--C1)' : '' }">
                <!-- :style="{ 'z-index': isMobile ? 'null' : 1, 'padding-top': isMobile ? '0px' : '100px', 'border-right': isMobile ? 'solid 1px var(--C1)' : 'none', 'background-color': isMobile ? 'var(--C1)' : 'var(--C1)' }"> -->
                <v-col class="py-2" @click="selectedView = 0" style="cursor: pointer;">
                    <v-row justify="center" align="center" style="padding: 9px 0px;"
                        :style="{ 'padding-top': isMobile ? '15px' : '7px', }">
                        <v-col cols="auto" v-if="isMobile" class="pl-0" align="left">
                            <v-btn variant="text" icon="mdi-menu"
                                style="border-radius: 5px; background-color: var(--C1); color: white;" size="35"
                                @click="drawerIsOpen = !drawerIsOpen">
                            </v-btn>
                        </v-col>
                        <v-card variant="flat" 
                            :style="isMobile ? { border: 'solid 1px var(--C1)', 'background-color': 'white' } : { 'background-color': 'transparent'}">
                            <v-list-item prepend-icon="mdi-account-circle"
                                style="color: var(--C1); border: none ;font-size: 30px;background-color: transparent; border-radius:0px !important;" class="pl-3 pr-2">
                                <template v-slot:title>
                                        <div style="font-weight: bold;color: var(--C1);text-transform: uppercase; max-height:70px;overflow: auto; white-space: normal" class="" >
                                            {{ groupInformation.name + ' - ' + userInformation.firstname }}
                                        </div>
                                </template>
                            </v-list-item>
                        </v-card>
                    </v-row>
                </v-col>
                <v-card v-if="drawerIsOpen || !isMobile" color="white"
                    :height="isMobileXs ? getOperatingSystem == 'IOS' ? '77vh' : '82vh' : '75vh'" variant="flat"
                    style="border: solid 1px var(--C4);" class="card">
                    <v-col class="pa-0" v-if="panelIsOpen == false">
                        <div v-for="elem in drawerElements" :key="elem.title" class="pb-1">
                            <v-list-item class="py-2" :prepend-icon="elem.prependIcon" :title="elem.title"
                                style="color: var(--C1); border: none ;font-size: 25px;background-color: transparent; border-radius:0px !important;"
                                :class="selectedView == elem.selectedView ? 'buttonDrawer' : 'buttonDrawerNotSelected'"
                                @click="function () {
                                    selectedView = elem.selectedView
                                    setSelectedVew(elem.selectedView)
                                }">
                            </v-list-item>
                        </div>
                        <v-list-item
                            :style="{ 'background-color': selectedView == 3 ? 'var(--C1)' : 'transparent', 'color': selectedView == 3 ? 'white' : '' }"
                            class="py-2" :class="selectedView == 3 ? 'buttonDrawer' : 'buttonDrawerNotSelected'"
                            prepend-icon="mdi-head-cog-outline" title="Votre Jauris"
                            style="color: var(--C1); border: none ;font-size: 25px;background-color: transparent; border-radius:0px !important;"
                            @click="function () { modalIsOpen['configIsOpen'] = true }"></v-list-item>
                        <!-- <v-list-item
                            :style="{ 'background-color': selectedView == 5 ? 'var(--C1)' : 'transparent', 'color': selectedView == 5 ? 'white' : '' }"
                            class="py-2" :class="selectedView == 5 ? 'buttonDrawer' : 'buttonDrawerNotSelected'"
                            prepend-icon="mdi-security" title="Administration"
                            style="color: var(--C1); border: none ;font-size: 25px;background-color: transparent; border-radius:0px !important;"
                            @click="function () { modalIsOpen['administrationIsOpen'] = true }"></v-list-item> -->
                    </v-col>
                    <div style="position: absolute; bottom: 0px;width: 100%; color: var(--C1);" class="">
                        <div v-if="isMobile" style="position: absolute; bottom: 0px;width: 100%;">
                            <v-list-item class="buttonDrawerNotSelected py-2" prepend-icon="mdi-help-circle-outline"
                                title="Aide"
                                style="width: 100%; border: none;font-size: 25px; border-radius:0px !important;border:none !important;"
                                @click="function () { modalIsOpen['helpisOpen'] = true }"></v-list-item>
                            <v-list-item class="buttonDrawerNotSelected py-2" prepend-icon="mdi-logout"
                                title="Se déconnecter"
                                style="width: 100%; border: none;font-size: 25px;border-radius:0px;"
                                @click="logOut"></v-list-item>
                        </div>
                        <div v-else style="position: absolute; bottom: 0px;width: 100%;">
                            <v-list-item class="buttonDrawerNotSelected py-2" prepend-icon="mdi-help-circle-outline"
                                title="Aide"
                                style="width: 100%; border: none;font-size: 25px; border-radius:0px !important;border:none !important;"
                                @click="function () { modalIsOpen['helpisOpen'] = true }"></v-list-item>
                            <v-list-item class="buttonDrawerNotSelected py-2" prepend-icon="mdi-logout"
                                title="Se déconnecter"
                                style="width: 100%; border: none;font-size: 25px; border-radius:0px !important;"
                                @click="logOut"></v-list-item>
                        </div>
                    </div>
                </v-card>
                <div v-if="!drawerIsOpen && isMobile">
                    <v-col cols="auto" class="pl-2">
                        <v-icon size="35" style="color: var(--C1);">mdi-account-circle</v-icon>
                    </v-col>
                </div>
            </v-navigation-drawer>
        </template>
    </v-hover>
</template>

<script>

import HelpModal from '@/components/dashboard/dashboard-modal-help.vue'
import ConfigurationView from '@/view/view-configuration.vue'
// import AdminView from '@/view/view-admin.vue'

export default {
    data() {
        return {
            selectedView: 0,
            modalIsOpen: {},
            panelIsOpen: false,
            drawerIsHover: false,
            drawerIsOpen: false,
        }
    },
    props: {
        getOperatingSystem: {},
        getLinksFormInformation: {},
        localDrawerIsOpen: {},
        userInformation: {},
        getUserData: {},
        groupInformation: {},
        blogListe: {},
        setSelectedVew: { type: Function },
        setLocalDrawer: { type: Function }
    },
    mounted() {
        if (!this.isMobile)
            this.drawerIsOpen = true
    },
    components: {
        HelpModal,
        ConfigurationView,
        // AdminView
    },
    methods: {
        logOut() {
            localStorage.clear()
            this.$router.push({ name: 'login' })
        },
    },
    watch: {
        drawerIsOpen(val) {
            this.setLocalDrawer(val)
        },
        localDrawerIsOpen(val) {
            this.drawerIsOpen = val
        },
        isMobile(val) {
            if (val) {
                this.isDrawerHover = true
            } else {
                this.isDrawerHover = false
            }
        },
        selectedView() {
            if (this.isMobile)
                this.drawerIsOpen = false
        }

    },
    computed: {
        drawerElements() {
            let data = [
                { title: 'Mes articles', prependIcon: "mdi-bulletin-board", color: "#E57373", selectedView: 0 },
                // { title: 'Calendrier', prependIcon: "mdi-calendar-clock", color: "#A1887F", selectedView: 1 },
                // { title: 'Galerie photo', prependIcon: "mdi-image-multiple", color: "#A1887F", selectedView: 4 },
            ]

            console.log('userInformation', this.userInformation)
            if (this.userInformation) {
                if (this.userInformation.settings) {
                    if (this.userInformation.settings.show_album == true)
                        data.push({ title: 'Galerie photo', prependIcon: "mdi-image-multiple", color: "#A1887F", selectedView: 4 })
                    if (this.userInformation.settings.show_seo == true)
                        data.push({ title: 'Seo', prependIcon: "mdi-web", color: "#A1887F", selectedView: 5 })
                }
            }

            return data
        },
        isMobile() {
            return this.$vuetify.display.mobile
        },
        isMobileXs() {
            return this.$vuetify.display.xs
        },
    }
}

</script>